body {
  margin: 0;
  font-family: Inter, "Fira Code", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: rgb(10, 25, 47);

  font-feature-settings: 'pnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on, 'ss02' on, 'ss04' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv08' on, 'cv09' on, 'cv10' on, 'cv11' on;
}

html {
  /*scroll-behavior: smooth;*/
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  display: grid;
  min-height: 100vh;
}

#diamond {
  stroke-dasharray: 1397;
  stroke-dashoffset: 1397;
  animation: diamond 1s ease;
  animation-fill-mode: forwards;
}

#logo-l {
  animation: logo-l 200ms ease;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

#logo-c {
  opacity: 0;
  animation: logo-c 1s ease;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes logo-l  {
  to {
    transform: scale(0, 0);
  }
}

@keyframes logo-c {
  100% {
    opacity: 1;
  }
}

@keyframes diamond {
  to {
    stroke-dashoffset: 0;
  }
}

/*@keyframes fadeIn {*/
/*  from {*/
/*    opacity: 0*/
/*  }*/
/*  to {*/
/*    opacity: 1*/
/*  }*/
/*}*/

/*.fade-in {*/
/*  animation: fadeIn 3000ms;*/
/*}*/